import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import Buefy from 'buefy';
import './assets/scss/app.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas, far, fab);
Vue.component('font-awesome-icon', FontAwesomeIcon);

import moment from 'moment';
moment.locale('pt');
Vue.prototype.moment = moment;

Vue.use(Buefy, {
	defaultIconComponent: 'font-awesome-icon',
	defaultIconPack: 'fas',
});

Vue.config.productionTip = false;

import './mixins/index';

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
