const state = {
	client: [],
	articles: []
};

const initial = Object.assign(state);


const getters = {
	getClient(state) {
		return state.client;
	},
	getArticles(state) {
		return state.articles;
	}
};

const mutations = {
	setClient(state, payload) {
		state.client = payload;
	},
	setArticles(state, payload) {
		state.articles = payload;
	},
    resetStore(s) {
		s.client = [];
        s.articles = [];
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};

