import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js';


Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Auth',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Auth.vue'),
	meta: {
		onlyNoAuth: true,
	}
  },

	{
		path: '/emit',
		name: 'Emit',
		component: () => import(/* webpackChunkName: "about" */ '@/views/emit/Emit.vue'),
		meta: {
			requiresAuth: true,
		},
	},
  {
		path: '/emitform',
		name: 'EmitForm',
		component: () => import(/* webpackChunkName: "about" */ '@/views/emit/EmitForm.vue'),
		meta: {
			requiresAuth: true,
		},
	},
  {
		path: '/consult',
		name: 'Consult',
		component: () => import(/* webpackChunkName: "about" */ '@/views/consult/Consult.vue'),
		meta: {
			requiresAuth: true,
		}
	},
  {
		path: '/settings',
		name: 'Settings',
		component: () => import(/* webpackChunkName: "about" */ '@/views/settings/Settings.vue'),
		meta: {
			requiresAuth: true,
		},
	},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
	if (requiresAuth) {
		// if (!store.getters['auth/getToken']) return next({ name: 'Auth' });
	}

	const onlyNoAuth = to.matched.some((record) => record.meta.onlyNoAuth);
	if (onlyNoAuth) {
		if (store.getters['auth/getToken']) return next({ name: 'Emit' });
	}

	/*const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
	if (requiresAdmin) {
		if (store.getters['auth/getUser'].user_type != 'admin') return next({ name: 'Home' });
	}*/

	next();
});

export default router
