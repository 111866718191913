import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import search from './modules/search';
import transport from './modules/transport';

Vue.use(Vuex);

import VuexPersistence from 'vuex-persist';
const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
	key: process.env.VUE_APP_NAME,
});

export default new Vuex.Store({
	modules: {
		auth,
		search,
		transport
	},
	plugins: [vuexLocal.plugin],
});
