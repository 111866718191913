import store from '@/store/index';
import { ToastProgrammatic as Toast } from 'buefy';
import router from '@/router/index';

export default {
	computed: {
		getNif() {
			return store.getters['auth/getNif'];
		},

        getName() {
            return store.getters['auth/getName'];
        },

		getToken() {
			return store.getters['auth/getToken'];
		},
	},
	methods: {
		/*addUser(user) {
			store.commit('auth/addUser', user);
		},*/
		addToken(token) {
			store.commit('auth/setToken', token);
		},
        addNif(nif) {
			store.commit('auth/setNif', nif);
		},
        addName(name) {
			store.commit('auth/setName', name);
		},
		onSessionExpired() {
			Toast.open({
				message: 'Sessão expirada, repita o processo!',
				type: 'is-secondary',
				position: 'is-bottom',
			});
			localStorage.removeItem(process.env.VUE_APP_NAME,);
			router.push('/');
		},
	},
};
