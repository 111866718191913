const state = {
	clients: [],
	articles: []
};

const initial = Object.assign(state);


const getters = {
	getClients(state) {
		return state.clients;
	},
	getArticles(state) {
		return state.articles;
	}
};

const mutations = {
	setClients(state, payload) {
		state.clients = payload;
	},
	setArticles(state, payload) {
		state.articles = payload;
	},
    resetStore(s) {
		s.clients = [];
        s.articles = [];
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};

