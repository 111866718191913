import Vue from 'vue';

// initial state
const state = {
	//user: '',
	token: null,
    nif: null,
    name: null
};

// getters
const getters = {
	getToken(state, getters) {
		return state.token;
	},

    getNif(state, getters) {
		return state.nif;
	},


    getName(state, getters) {
		return state.name;
	},

	/*getUser(state, getters) {
		return state.user;
	},*/
};

// actions
const actions = {
	/*authenticateUser({ commit }, userData) {
		// Set token
		const token = userData.headers.authorization;
		commit('setToken', token);
	},*/
};

// mutations
const mutations = {
	setToken(state, token) {
		state.token = token;
	},

    setNif(state, nif) {
        state.nif = nif;
    },

    setName(state, name) {
        state.name = name;
    },

	resetStore(s) {
		s.token = null;
        s.nif = null;
        s.name = null;
	},

	/*addUser(state, user) {
		Vue.set(state, 'user', user);
	},*/
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
